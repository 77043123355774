import {CustomCssVarsFn} from '@wix/yoshi-flow-editor';
import {baseCustomCssVars, CustomCssVarsFnParams} from '../../baseCustomCssVars';
import styleParams from '../GridGallery/stylesParams';

export const customCssVars: CustomCssVarsFn = (params: CustomCssVarsFnParams) => {
  return {
    ...baseCustomCssVars(params),
  };
};

export default styleParams;
