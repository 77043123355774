import {CustomCssVarsFnParams} from '../../../baseCustomCssVars';

/* istanbul ignore file */
// eslint-disable-next-line sonarjs/cognitive-complexity
export const heroCustomCssVars = ({styleParams: styles, isMobile}: CustomCssVarsFnParams) => {
  const showCategoryHeaderImage = styles.booleans.gallery_showCategoryHeaderImage;
  const showCategoryHeaderDescription = styles.booleans.gallery_showCategoryHeaderDescription;
  const showCategoryHeaderName = styles.booleans.gallery_showCategoryHeaderName;

  return {
    showCategoryHeaderImagePaddingTop: (showCategoryHeaderImage && (isMobile ? '16px' : '24px')) || 'inherit',
    showCategoryHeaderNameOrDescription: showCategoryHeaderName || showCategoryHeaderDescription,
    categoryHeaderSpaceBetweenTitleDescription:
      showCategoryHeaderName && showCategoryHeaderDescription
        ? styles.numbers.gallery_categoryHeaderSpaceBetweenTitleDescription.toString()
        : '0',
    showHeroSection:
      styles.booleans.gallery_showCategoryHeaderSection &&
      (showCategoryHeaderImage || showCategoryHeaderName || showCategoryHeaderDescription),
    showCategoriesBreadcrumbs: styles.booleans.gallery_showCategoriesBreadcrumbs,
    categoryCountAndFilterContainerJustifyContent: styles.booleans.gallery_showCategoriesProductsCounter
      ? 'space-between'
      : 'flex-end',
    categoryHeaderImageMinHeight: showCategoryHeaderImage
      ? `${styles.numbers.gallery_categoryHeaderImageMinHeight}px`
      : 'unset',
    categoryDescriptionContainerColor: showCategoryHeaderImage
      ? styles.colors.gallery_categoryDescriptionContainerColor
      : 'unset',
    categoryDescriptionContainerBorderColor: showCategoryHeaderImage
      ? styles.colors.gallery_categoryDescriptionContainerBorderColor
      : 'unset',
    categoryDescriptionContainerBorderWidth: showCategoryHeaderImage
      ? `${styles.numbers.gallery_categoryDescriptionContainerBorderWidth}px`
      : 'unset',
    categoryDescriptionContainerBorderStyle: showCategoryHeaderImage ? 'solid' : 'unset',
    categoryDescriptionContainerCornerRadius: showCategoryHeaderImage
      ? `${styles.numbers.gallery_categoryDescriptionContainerCornerRadius}px`
      : 'unset',
    categoryDescriptionContainerPadding: showCategoryHeaderImage ? '24px' : 'inherit',
    categoryDescriptionContainerMargin: showCategoryHeaderImage ? '24px' : 'inherit',
  };
};
