import React, {RefObject} from 'react';
import s from './AddToCartButton.scss';
import type {IProduct} from '../../../../types/galleryTypes';
import classNames from 'classnames';
import {withTranslations} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {StatesButton, StatesButtonStates, ThreeDotsLoader} from 'wix-ui-tpa/cssVars';
import {classes as addToCartStyle, st as addToCartStyles} from './AddToCartButton.st.css';
import {AddToCartState} from '@wix/wixstores-client-storefront-sdk/dist/es/src/services/AddToCartService/constants';
import {AddToCartActionStatus} from '../../../../constants';

export enum DataHook {
  AddToCartButton = 'product-item-add-to-cart-button',
  AddToCartLoadingIndicator = 'product-item-add-to-cart-loading-indicator',
}

export interface IAddToCart {
  index: number;
  product: IProduct;
  quantity: number;
  showOnHoverClassName: string;
  onAddToCartClicked(): void;

  addedToCartStatus: {
    [productId: string]: AddToCartActionStatus;
  };
  addToCartState: AddToCartState;
  handleAddToCart: ({productId, index, quantity}: {productId: string; index: number; quantity: number}) => void;
  updateAddToCartStatus: (productId: string, status: AddToCartActionStatus) => void;

  galleryAddToCartButtonText: string;
  addToCartContactSeller: string;
  addToCartOutOfStock: string;
  galleryAddToCartPreOrderButtonText: string;
  shouldShowAddToCartSuccessAnimation: boolean;

  shouldShowMobile: boolean;
  shouldShowOnHover: boolean;
  buttonWidth: string;
}

export class AddToCartComp extends React.Component<IAddToCart> {
  public addToCartButtonRef: RefObject<StatesButton> = React.createRef();

  private get isAddToCartEnabled() {
    const {addToCartState} = this.props;
    const isEnabledState = addToCartState === AddToCartState.ENABLED;
    const isPreOrderState = addToCartState === AddToCartState.PRE_ORDER;
    return isEnabledState || isPreOrderState;
  }

  private get isInProgress() {
    const {addedToCartStatus} = this.props;
    return addedToCartStatus[this.props.product.id] === AddToCartActionStatus.IN_PROGRESS;
  }

  private readonly handleAddToCartButtonClick = () => {
    const {
      product: {id: productId},
      index,
      quantity,
      handleAddToCart,
      onAddToCartClicked,
    } = this.props;

    handleAddToCart({productId, index, quantity});
    onAddToCartClicked();
  };

  private readonly handleNotificationEnd = () => {
    this.props.updateAddToCartStatus(this.props.product.id, AddToCartActionStatus.IDLE);
  };

  private get addToCartText(): string {
    const {
      galleryAddToCartButtonText,
      addToCartContactSeller,
      addToCartOutOfStock,
      galleryAddToCartPreOrderButtonText,
      addToCartState,
    } = this.props;
    if (addToCartState === AddToCartState.DISABLED) {
      return addToCartContactSeller;
    }
    if (addToCartState === AddToCartState.OUT_OF_STOCK) {
      return addToCartOutOfStock;
    }
    if (addToCartState === AddToCartState.PRE_ORDER) {
      return galleryAddToCartPreOrderButtonText;
    }
    return galleryAddToCartButtonText;
  }

  private get addToCartAnimationState(): StatesButtonStates {
    const {addedToCartStatus, shouldShowAddToCartSuccessAnimation} = this.props;
    const isAddedSuccessfully = addedToCartStatus[this.props.product.id] === AddToCartActionStatus.SUCCESSFUL;
    if (shouldShowAddToCartSuccessAnimation && isAddedSuccessfully) {
      return StatesButtonStates.SUCCESS;
    } else if (this.isInProgress) {
      return StatesButtonStates.IN_PROGRESS;
    }
    return StatesButtonStates.IDLE;
  }
  public render() {
    const {showOnHoverClassName, shouldShowMobile, shouldShowOnHover} = this.props;

    return (
      <div className={s.addToCartBtnContainer}>
        <StatesButton
          state={this.addToCartAnimationState}
          idleContent={this.addToCartText}
          inProgressContent={
            <div className={addToCartStyle.threeDotsButtonWrapper}>
              <ThreeDotsLoader
                className={addToCartStyle.threeDotsButton}
                data-hook={DataHook.AddToCartLoadingIndicator}
              />
            </div>
          }
          onNotificationEnd={this.handleNotificationEnd}
          ref={this.addToCartButtonRef}
          onClick={this.handleAddToCartButtonClick}
          disabled={!this.isAddToCartEnabled}
          className={classNames(
            s.addToCartBtn,
            addToCartStyles(addToCartStyle.addToCartButton, {
              useMobileFont: shouldShowMobile,
            }),
            {
              [showOnHoverClassName]: shouldShowOnHover && !shouldShowMobile,
            }
          )}
          fullWidth
          data-hook={DataHook.AddToCartButton}
        />
      </div>
    );
  }
}

export const AddToCartButton = withTranslations()(AddToCartComp);
