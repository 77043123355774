import React, {useMemo} from 'react';
import {CssVarsKeys} from '../../../baseCustomCssVars';
import {IGalleryGlobalProps} from '../../../gallery/galleryGlobalStrategy';
import {useStylesParams} from '../../../stylesParamsContext';
import {useStyles} from '@wix/tpa-settings/react';
import {useEnvironment} from '@wix/yoshi-flow-editor';

type ConditionalRenderProps = {
  by: Partial<CssVarsKeys>;
  dataHook?: string;
} & Omit<React.HTMLProps<HTMLDivElement>, 'style'> &
  IGalleryGlobalProps;

export const ConditionalRender: React.FunctionComponent<ConditionalRenderProps> = ({
  by,
  className,
  dataHook,
  children,
  ...props
}: ConditionalRenderProps) => {
  const stylesParams = useStylesParams();
  const styles = useStyles();
  const {isEditor} = useEnvironment();
  const memoizedGetStylesForAllBreakpoints = useMemo(() => styles.getStylesForAllBreakpoints(), [styles]);
  const memoizedGetCustomCssVarsForAllBreakpointsMemoized = useMemo(
    () => styles.getCustomCssVarsForAllBreakpoints(),
    [styles]
  );
  const shouldRender = () => {
    if (by in stylesParams) {
      return memoizedGetStylesForAllBreakpoints.some((stylesPerBreakpoint) => stylesPerBreakpoint.booleans[by]);
    }

    return memoizedGetCustomCssVarsForAllBreakpointsMemoized.some((stylesPerBreakpoint) => stylesPerBreakpoint[by]);
  };

  if (!isEditor && !shouldRender()) {
    return null;
  }

  const style: React.CSSProperties = {
    display: `var(--${by.replace(':', '-')}-display, inherit)`,
  };

  return (
    <div className={className} data-hook={dataHook} style={style} {...props}>
      {children}
    </div>
  );
};
